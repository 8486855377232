import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

export const ModalContext = React.createContext({
  open: false,
  defaultFormValue: {},
  openModal: () => {},
  openModalByDefault: () => {},
  closeModal: () => {},
});

export default function ModalProvider({ children }) {
  const [open, setVisible] = useState(false);
  const [defaultFormValue, setDefaultFormValue] = useState({});

  const closeModal = () => {
    setVisible(false);
    setDefaultFormValue({});
  };

  const openModal = () => setVisible(true);

  const openModalByDefault = (defaultFormValue, isContactUs = false) => {
    setDefaultFormValue(defaultFormValue);
    isContactUs && setVisible(true);
  };

  const contextValue = {
    open,
    defaultFormValue,
    openModal: useCallback(() => openModal(), []),
    closeModal: useCallback(() => closeModal(), []),
    openModalByDefault: useCallback(
      (value, isContactUs) => openModalByDefault(value, isContactUs),
      []
    ),
  };

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
}

ModalProvider.propTypes = {
  children: PropTypes.array,
};
