import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';

const SBCDialog = ({ children, disableBackdropClick, onClose, ...rest }) => {
  const handleClose = (event, reason) => {
    if (
      disableBackdropClick &&
      (reason === 'backdropClick' || reason === 'escapeKeyDown')
    ) {
      return false;
    }
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <Dialog onClose={handleClose} {...rest}>
      {children}
    </Dialog>
  );
};

SBCDialog.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  disableBackdropClick: PropTypes.bool,
  onClose: PropTypes.func,
};

export default SBCDialog;
