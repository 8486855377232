import React from 'react';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import useAPIError from '../hooks/useAPIError';
import WarningModalIcon from '../components/icons/WarningModal';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import SBCDialog from './SBCDailog';
import { signout } from '../api/SbcBackendApi';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function APIErrorNotification() {
  const { error, removeError } = useAPIError();
  const classes = useStyles();

  const handleClose = () => {
    if (error?.status === 403) {
      signout();
    }
    removeError();
  };

  return (
    <SBCDialog
      open={!!error}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      disableBackdropClick
      aria-labelledby="api-error-dialog"
      aria-describedby="aapi-error-dialog-description"
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        className={classes.titleWrapper}
      >
        <WarningModalIcon width={35} />
        <DialogTitle id="api-error-dialog">
          {error?.status === 403 ? 'Session Expired' : 'Error'}
        </DialogTitle>
      </Box>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description"
          className={classes.errorText}
        >
          {error?.message || 'Something went wrong! Please try again later.'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} size="large">
          {error?.status === 403 ? 'Login' : 'Close'}
        </Button>
      </DialogActions>
    </SBCDialog>
  );
}

const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    paddingLeft: theme.spacing(2),
  },
  errorText: {
    maxWidth: theme.breakpoints.values.xl,
    marginBottom: theme.spacing(0),
    padding: theme.spacing(1),
  },
}));
