import { useContext } from 'react';
import { ModalContext } from '../common/providers/ModalProvider';

function useModal() {
  const { open, defaultFormValue, openModal, closeModal, openModalByDefault } =
    useContext(ModalContext);
  return {
    open,
    defaultFormValue,
    openModal,
    closeModal,
    openModalByDefault,
  };
}

export default useModal;
