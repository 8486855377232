import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const Loader = ({ fullHeight, customStyle }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={clsx(
        customStyle ? customStyle : classes.wrapper,
        fullHeight ? classes.fullHeight : null
      )}
    >
      <CircularProgress />
    </Grid>
  );
};

Loader.propTypes = {
  fullHeight: PropTypes.bool,
  customStyle: PropTypes.object,
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  fullHeight: {
    height: '100vh',
  },
}));

export default Loader;
