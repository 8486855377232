import {
  PROD_CHAT_CONFIG,
  SIT_CHAT_CONFIG,
  UAT_CHAT_CONFIG,
} from '../constants';

// get chat config based on env
let CHAT_CONFIG = SIT_CHAT_CONFIG;
switch (process.env.REACT_APP_ENV) {
  case 'uat':
    CHAT_CONFIG = UAT_CHAT_CONFIG;
    break;

  case 'prod':
    CHAT_CONFIG = PROD_CHAT_CONFIG;
    break;

  default:
    CHAT_CONFIG = SIT_CHAT_CONFIG;
    break;
}

/* eslint-disable no-undef */
const initESW = function (gslbBaseURL) {
  embedded_svc.settings.displayHelpButton = true; //Or false
  embedded_svc.settings.language = ''; //For example, enter 'en' or 'en-US'

  //embedded_svc.settings.defaultMinimizedText = '...'; //(Defaults to Chat with an Expert)
  //embedded_svc.settings.disabledMinimizedText = '...'; //(Defaults to Agent Offline)

  //embedded_svc.settings.loadingText = ''; //(Defaults to Loading)
  //embedded_svc.settings.storageDomain = 'yourdomain.com'; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)

  // Settings for Chat
  //embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
  // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
  // Returns a valid button ID.
  //};
  //embedded_svc.settings.prepopulatedPrechatFields = {}; //Sets the auto-population of pre-chat form fields
  //embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
  //embedded_svc.settings.offlineSupportMinimizedText = '...'; //(Defaults to Contact Us)

  embedded_svc.settings.enabledFeatures = ['LiveAgent'];
  embedded_svc.settings.entryFeature = 'LiveAgent';

  // Chat config
  embedded_svc.init(
    CHAT_CONFIG.baseUrl,
    CHAT_CONFIG.communityUrl,
    gslbBaseURL,
    CHAT_CONFIG.chatID,
    'QTB_Sierra_26',
    { ...CHAT_CONFIG.config }
  );
};

const initChat = () => {
  if (!window.embedded_svc) {
    const scriptEl = document.createElement('script');
    scriptEl.setAttribute(
      'src',
      `${CHAT_CONFIG.baseUrl}/embeddedservice/5.0/esw.min.js`
    );
    scriptEl.onload = function () {
      initESW(null);
    };
    document.body.appendChild(scriptEl);
  } else {
    initESW('https://service.force.com');
  }
};

export default initChat;
