import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/performance';

let firebaseAuth;
let firestore;
let firebasePerf;

// Initialize firebase
export const initFirebase = async () => {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
    measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
  };
  await firebase.initializeApp(firebaseConfig);
  firebaseAuth = await firebase.auth();
  await firebaseAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
  firestore = await firebase.firestore();
  firebasePerf = await firebase.performance();
};

// firebase storage reference
const storageRef = async (bucket, path) => {
  if (path) return await firebase.app().storage(`gs://${bucket}`).ref(path);
  else return await firebase.app().storage(`gs://${bucket}`).ref();
};

export { storageRef, firestore, firebaseAuth, firebasePerf };
