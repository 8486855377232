import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ArrowDownIcon from '../components/icons/ArrowDownIcon';

const useStyles = makeStyles(() => ({
  select: {
    '&:before': {
      borderColor: '#14477D',
    },
    '&:after': {
      borderColor: '#14477D',
    },
    '&:not(.Mui-disabled):hover::before': {
      borderColor: '#14477D',
    },
  },
  root: {
    '& .MuiInputBase-root': {
      backgroundColor: (prop) => {
        return prop?.color ? prop?.prop : '#F7F7FE';
      },
      fontSize: (props) => props.fontSize || '18px',
      fontWeight: '300',
    },
    '& .MuiInputLabel-root': {
      color: '#14477D',
      '& .red-asterisk': {
        color: '#FF5630',
      },
      whiteSpace: 'nowrap',
    },
    '& .MuiInput-formControl': {
      marginTop: (props) =>
        props.formControlStyle?.marginTop && props.formControlStyle?.marginTop,
    },
    '& .MuiTypography-body1': {
      color: '#cccccc',
    },
  },
}));

const CustomSelectField = ({
  label,
  value,
  onChange,
  options,
  error = false,
  helperText = '',
  placeholder = '',
  disabled = false,
  defaultValue,
  InputLabelProps = {},
  fontSize,
  formControlStyle,
  ...props
}) => {
  const { sx } = props;
  const color = sx?.color;
  const classes = useStyles({ color, fontSize, formControlStyle });
  return (
    <FormControl
      className={classes.root}
      error={!!error}
      fullWidth
      disabled={disabled}
      {...props}
    >
      <InputLabel {...InputLabelProps} shrink>
        {label}
      </InputLabel>
      <Select
        value={value}
        onChange={onChange}
        className={classes.select}
        label={label}
        disableUnderline={color === 'none' ? true : false}
        variant={color === 'none' ? 'standard' : undefined}
        IconComponent={ArrowDownIcon}
        defaultValue={defaultValue}
        displayEmpty
        renderValue={
          value !== ''
            ? undefined
            : () => <Typography>{placeholder}</Typography>
        }
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

CustomSelectField.propTypes = {
  label: PropTypes.node.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.node.isRequired,
    })
  ).isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  InputLabelProps: PropTypes.object,
  sx: PropTypes.object,
  fontSize: PropTypes.string,
  formControlStyle: PropTypes.object,
};

export default CustomSelectField;
