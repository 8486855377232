import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import SuccessModalIcon from '../icons/SuccessModal';
import WarningModalIcon from '../icons/WarningModal';
import { MODAL_STATES } from '../../data/constants';
import { useTranslation } from 'react-i18next';

const ConfirmationModal = ({
  open = false,
  onClose,
  successProps,
  failureProps,
  type,
  children,
  btnText,
  isSmallScreen,
  ...other
}) => {
  const classes = useStyles({ isSmallScreen });
  const { t } = useTranslation();

  const renderSuccessModalContent = () => {
    return (
      <div className={classes.modalContent}>
        <DialogContent>
          <Grid
            container
            direction={'column'}
            spacing={2}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Grid item>
              <SuccessModalIcon />
            </Grid>
            <Grid item>
              <Typography variant={'h5'} className={classes.semibold}>
                {successProps.title}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant={'body1'}>{successProps.subtitle}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={onClose} variant={'contained'} color="primary">
            {btnText ? btnText : t('done_btn')}
          </Button>
        </DialogActions>
      </div>
    );
  };

  const renderFailureModalContent = () => {
    return (
      <div className={classes.modalContent}>
        <DialogContent>
          <Grid
            container
            direction={'column'}
            spacing={2}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Grid item>
              <WarningModalIcon />
            </Grid>
            <Grid item>
              <Typography variant={'h5'} className={classes.semibold}>
                {failureProps.title}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant={'body1'}>{failureProps.subtitle}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={onClose} variant={'contained'}>
            Close
          </Button>
        </DialogActions>
      </div>
    );
  };

  return (
    <Dialog
      open={open}
      hideBackdrop={isSmallScreen ? true : false}
      PaperProps={{ className: classes.paper }}
      {...other}
    >
      {type === MODAL_STATES.main && children}
      {type === MODAL_STATES.success && renderSuccessModalContent()}
      {type === MODAL_STATES.failure && renderFailureModalContent()}
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  modalContent: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  },
  modalFooter: {
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  semibold: {
    fontWeight: 500,
  },
  paper: (props) =>
    props.isSmallScreen && {
      top: '110px',
      width: '99%',
      filter: 'none',
      margin: '0',
      maxWidth: '500px',
      height: '-webkit-fill-available',
      position: 'absolute',
    },
}));

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  type: PropTypes.oneOf([
    MODAL_STATES.main,
    MODAL_STATES.success,
    MODAL_STATES.failure,
  ]),
  onClose: PropTypes.func.isRequired,
  successProps: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
  }),
  failureProps: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
  }),
  children: PropTypes.node,
  btnText: PropTypes.string,
  isSmallScreen: PropTypes.string,
};

export default ConfirmationModal;
