import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from './useAuth';
import { firestore } from '../utils/firebase';
import firebase from 'firebase/app';
import { useAccount } from './useAccount';
import { getEmailTemplate, getSettings } from '../api/firestoreFunctions';
import { APP_EMAILS, FS_SETTENIGS_COLLECTION } from '../constants';
import { useFetch } from '../utils';
import { SBC_BACKEND_ENDPOINTS } from '../data/constants';

const EmailContext = createContext({});

/**
 * Custom hook for providing email settings and methods to consuming components.
 */
export const useEmail = () => useContext(EmailContext);

export const EmailProvider = ({ children }) => {
  const defaultEmail = useDefaultEmail();

  return (
    <EmailContext.Provider value={defaultEmail}>
      {children}
    </EmailContext.Provider>
  );
};

EmailProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * Default EmailProvider configuration.
 */
const useDefaultEmail = () => {
  const { user } = useAuth();
  const { account } = useAccount();

  const [emailSettings, setEmailSettings] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [statusCodeList, setStatusCodeList] = useState(undefined);
  const [recBundleTypes, setRecBundleTypes] = useState([]);

  useEffect(() => {
    const fetchEmailSettings = async () => {
      try {
        const res = await getSettings(FS_SETTENIGS_COLLECTION.common);
        const { email, statusCodes, recurringBundleTypes } = res.data();
        setEmailSettings(email);
        setStatusCodeList(statusCodes);
        setRecBundleTypes(recurringBundleTypes);
      } catch (err) {
        console.error('Failed to fetch email settings from Firestore', err);
        setError(err);
      }
    };

    fetchEmailSettings();
  }, []);

  /**
   * Posts email object to Firebase /emails collection.
   */
  const postEmail = async ({
    templateName,
    body,
    subject,
    internal = false,
    toEmail = user.user.email,
    userName = user.user.name,
    cc = '',
    bcc = '',
    meta = {},
    selectedAccount = {},
    isAdmin = false,
  }) => {
    if (isAdmin) {
      meta = { userEmail: selectedAccount.accountOwner };
    } else {
      meta = {
        ...meta,
        customerName: account.customerName,
        salesforceAccount: account.sfAccountId,
        skpAccount: account.skpCustomerId,
        userName: user.user.name,
        userEmail: user.user.email,
        dashboardAccount: account.id,
      };
    }

    const URL = SBC_BACKEND_ENDPOINTS.emailTemplate.replace(
      '{templateId}',
      templateName
    );
    const res = await useFetch(URL, 'GET');
    const { body: template } = res.response.data.emailTemplate;
    if (internal && meta) {
      body = `<br/><table>`;
      Object.keys(meta).forEach((key) => {
        body +=
          '<tr><td>' +
          key.toUpperCase() +
          '</td><td>' +
          meta[`${key}`] +
          '</td></tr>';
      });
      body += '</table>';
    }
    const emailBody = template
      .replace(
        '<VAR_USER>',
        internal ? 'IRM Representative' : isAdmin ? '' : userName
      )
      .replace('<VAR_BODY>', body);
    const email = {
      bcc: internal ? emailSettings.overrides.internal.bcc : bcc,
      body: emailBody,
      customerId: isAdmin ? selectedAccount.customerID : account.sfAccountId,
      cc: internal ? emailSettings.overrides.internal.cc : cc,
      sender: APP_EMAILS.support,
      status: 'Not Sent',
      subject,
      to: internal
        ? emailSettings.overrides.internal.to
        : isAdmin
        ? selectedAccount.accountOwner
        : toEmail,
      type: internal ? 'INTERNAL' : 'TO_CUSTOMER',
      createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
    };
    return firestore.collection(emailSettings.collection.inbound).add(email);
  };

  /**
   * Posts email object to re auth customer.
   */
  const postEmailForReAuthPage = async ({
    templateName,
    body,
    subject,
    internal = false,
    toEmail = user.user.email,
    userName = user.user.name,
    cc = '',
    bcc = '',
  }) => {
    const res = await getEmailTemplate(templateName);
    const { template } = res.data();
    const emailBody = template
      .replace('<VAR_USER>', internal ? 'IRM Representative' : userName)
      .replace('<VAR_BODY>', body);
    const email = {
      bcc: internal ? emailSettings.overrides.internal.bcc : bcc,
      body: emailBody,
      customerId: account.sfAccountId,
      cc: internal ? emailSettings.overrides.internal.cc : cc,
      sender: APP_EMAILS.support,
      status: 'Not Sent',
      subject,
      to: internal ? emailSettings.overrides.internal.to : toEmail,
      type: internal ? 'INTERNAL' : 'TO_CUSTOMER',
      createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
    };
    return firestore.collection(emailSettings.collection.inbound).add(email);
  };

  return {
    emailSettings,
    STATUS_CODES: statusCodeList,
    recBundleTypes,
    postEmail,
    postEmailForReAuthPage,
    error,
  };
};
