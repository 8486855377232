import React, { useEffect, useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import ContactUsForm from '../Forms/ContactUsForm';
import {
  Box,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { MODAL_INFO, MODAL_STATES, ROUTES } from '../../data/constants';
import ConfirmationModal from './ConfirmationModal';
import { useEmail } from '../../hooks/useEmail';
import useModal from '../../hooks/useModal';
import { SUPPORT_PHONE, PERMISSIONS } from '../../constants';
import { useAccount } from '../../hooks/useAccount';
import Loader from '../../common/Loader';
import { useTranslation } from 'react-i18next';
import { MAILS } from '../../utils/mails';
import { Close, InfoOutlined } from '@material-ui/icons';

const ContactUsModal = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles({ isSmallScreen });
  const { postEmail } = useEmail();
  const { open, closeModal, defaultFormValue } = useModal();
  const [loading, setLoading] = useState(false);
  const [modalState, setModalState] = useState(MODAL_STATES.main);
  const { isPermissionAvailable } = useAccount();
  const { t } = useTranslation();

  // Reset modal state
  useEffect(() => {
    open && setModalState(MODAL_STATES.main);
  }, [open]);

  /**
   * Creates email object (for both customer and rep) in Firestore.
   */
  const handleSubmit = async (values) => {
    setLoading(true);
    Promise.all([
      postEmail(MAILS.contactSupportMail(values)),
      postEmail(MAILS.contactSupportSFMail(values)),
    ])
      .then(() => {
        setModalState(MODAL_STATES.success);
      })
      .catch((err) => {
        console.log('Failed to send email', err);
        setModalState(MODAL_STATES.failure);
      })
      .finally(() => setLoading(false));
  };

  return (
    <ConfirmationModal
      open={open}
      type={modalState}
      onClose={closeModal}
      fullWidth={true}
      maxWidth={modalState === MODAL_STATES.main ? 'md' : 'sm'}
      successProps={MODAL_INFO.contactUs.success}
      failureProps={MODAL_INFO.contactUs.failure}
      isSmallScreen={isSmallScreen}
    >
      <Grid
        style={{
          top: '5px',
          right: '15px',
          position: 'absolute',
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={closeModal}
          aria-label="close"
        >
          <Close />
        </IconButton>
      </Grid>
      <DialogTitle className={classes.titleContainer}>
        <Typography className={classes.modalTitle}>
          {t('contact_us_btn')}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.contentContainer}>
        <Grid container direction={'column'}>
          <Grid item>
            <Box className={classes.infoText}>
              <InfoOutlined style={{ alignSelf: 'flex-start' }} />
              <Box ml={1}>
                {t('contact_us_modal_contact_phone_text_v1')}{' '}
                <Box component="span" className={classes.textBlue}>
                  {SUPPORT_PHONE}.
                </Box>
                {t('empty_space_one')}
                {t('contact_us_modal_link_to_more_info')}
                {t('empty_space_one')}
                <Link
                  href={ROUTES.faqs.path}
                  color={'textSecondary'}
                  underline={'always'}
                  className={classes.textBlue}
                >
                  {t('empty_space_one')}
                  {t('contact_us_modal_faq_link')}
                </Link>
              </Box>
            </Box>
          </Grid>
          {isPermissionAvailable(PERMISSIONS.faqs) && (
            <Grid item className={classes.centerText}></Grid>
          )}
          <Grid item>
            {loading ? (
              <Loader />
            ) : (
              <ContactUsForm
                defaultFormValue={defaultFormValue}
                onSubmit={handleSubmit}
                onCancel={closeModal}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </ConfirmationModal>
  );
};

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    padding: (props) =>
      !props.isSmallScreen
        ? theme.spacing(5, 12, 4, 12)
        : theme.spacing(2, 2, 0, 2),
  },
  contentContainer: {
    padding: (props) =>
      !props.isSmallScreen
        ? theme.spacing(0, 12, 5, 12)
        : theme.spacing(2, 2, 2, 2),
  },
  fullWidth: {
    width: '100%',
  },
  centerText: {
    textAlign: 'center',
  },
  semibold: {
    fontWeight: 500,
  },
  modalTitle: {
    fontFamily: 'Geometria, Open Sans',
    fontSize: '40px',
    fontWeight: 300,
    color: '#000',
  },
  infoText: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: (props) => (props.isSmallScreen ? '16px' : 0),
    fontSize: (props) => (props.isSmallScreen ? 18 : 14),
  },
  textBlue: {
    color: '#1B75BC',
  },
}));

export default ContactUsModal;
