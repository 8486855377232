import React from 'react';
import {
  FormControl,
  FormHelperText,
  TextareaAutosize,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiInputBase-root': {
      backgroundColor: '#F7F7FE',
      fontWeight: '300',
      color: '#14477D',
      '& .red-asterisk': {
        color: '#FF5630',
      },
      whiteSpace: 'nowrap',
    },
  },
  label: {
    color: '#14477D',
    fontSize: '12px',
    '& .red-asterisk': {
      color: '#FF5630',
    },
  },
  textarea: {
    width: '100%',
    padding: '16px 8px 8px 16px',
    fontSize: '18px',
    fontFamily: 'Interstate, Open Sans',
    border: 'none',
    borderBottom: '1px solid var(--color-primary-im-dark-blue, #14477D)',
    backgroundColor: 'var(--color-background-light-blue, #F7F7FE)',
    resize: 'vertical',
    outline: 'none',
    color: '#3e3e3e',
    '&::placeholder': {
      color: '#cccccc',
      opacity: 0.96,
    },
  },
  helperText: {
    color: '#666663',
    position: 'absolute',
    bottom: '-25px',
    right: '0',
    marginRight: '0',
  },
}));

const CustomTextarea = ({
  label,
  value,
  onChange,
  minRows,
  maxRows,
  placeholder,
  helperText,
}) => {
  const classes = useStyles();

  return (
    <FormControl variant="filled" fullWidth className={classes.root}>
      <label shrink className={classes.label}>
        {label}
      </label>
      <TextareaAutosize
        value={value}
        minRows={minRows}
        maxRows={maxRows}
        onChange={onChange}
        placeholder={placeholder}
        className={classes.textarea}
      />
      <FormHelperText className={classes.helperText}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};

CustomTextarea.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
};

export default CustomTextarea;
