import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import queryString from 'query-string';
import { ThemeProvider } from '@material-ui/core/styles';
import { OKTA_ERROR_CODES, OKTA_LOGIN_URI } from './constants';
import { LOCAL_STORAGE_KEYS } from './data/constants';
import { getWithExpiry, setWithExpiry, userLogin } from './utils';
import theme from './theme/MUITheme';
import { AuthProvider } from './hooks/useAuth';
import ErrorScreen from './Pages/ErrorScreen';
import { firebaseAuth, initFirebase } from './utils/firebase';
import App from './App';
import APIErrorProvider from './common/providers/APIErrorProvider';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import Loader from './common/Loader';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

TagManager.initialize(tagManagerArgs);

/**
 * Main render method to determine which app experience is available
 */
const renderApp = (user) => {
  const token = getWithExpiry(LOCAL_STORAGE_KEYS.oktaToken);
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <Suspense fallback={<Loader fullHeight />}>
        <AuthProvider user={user} token={token}>
          <APIErrorProvider>
            <I18nextProvider i18n={i18n}>
              <App user={user} />
            </I18nextProvider>
          </APIErrorProvider>
        </AuthProvider>
      </Suspense>
    </ThemeProvider>,
    document.getElementById('root')
  );
};

const renderLoading = () => {
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <Loader fullHeight />
    </ThemeProvider>,
    document.getElementById('root')
  );
};

const renderError = (error) => {
  const message = 'Something went wrong! Please try again later.';
  ReactDOM.render(
    <ErrorScreen error={error} message={message} />,
    document.getElementById('root')
  );
};

/**
 * Login method responsible for:
 *  - Redirecting to Okta if not previously logged-in
 *  - Calling sbc-backend to with Okta-provided JWT
 *  - Persisting user object in localStorage
 */
const login = async () => {
  await initFirebase(); // Initialize firebase
  renderLoading();
  // 1. check if JWT is present in URL hash
  let queryParams = queryString.parse(location.hash);
  let jwt = queryParams.access_token;
  // handle user access removed error
  if (queryParams.error === OKTA_ERROR_CODES.access_denied) {
    history.pushState('', '', location.href.replace(location.hash, '')); // remove query params in url
    renderError(queryParams.error_description);
    return;
  }
  let userSession = getWithExpiry(LOCAL_STORAGE_KEYS.user, true);
  if (jwt) {
    // 2. Clean up token from URL & persist in localStorage
    history.pushState('', '', location.href.replace(location.hash, ''));
    setWithExpiry(LOCAL_STORAGE_KEYS.oktaToken, jwt);
    setWithExpiry(LOCAL_STORAGE_KEYS.oktaIdToken, queryParams.id_token); //used in logout
    if (!userSession) {
      // 3. Login to the dashboard
      try {
        const res = await userLogin(jwt);
        localStorage.setItem('app_notifiction', true); // used to display one time notification on login
        res.user ? renderApp(res) : renderError(res.message);
      } catch (error) {
        renderError(error.message);
      }
    } else {
      // 4. if user is already present && session has NOT expired, render the app
      persistLogin(userSession);
    }
  } else if (userSession) {
    // 5. if user is already present && session has NOT expired, render the app
    persistLogin(userSession);
  } else {
    // 6. If user session is not available redirect to okta login page
    location.assign(OKTA_LOGIN_URI);
  }
};

const persistLogin = async (userSession) => {
  try {
    userSession.user.firstTimeUser = false;
    await firebaseAuth.signInWithCustomToken(userSession.firebaseToken);
    renderApp(userSession);
  } catch (error) {
    renderError(error.message);
  }
};

login();
