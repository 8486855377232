import * as React from 'react';

const WarningModalIcon = (props) => (
  <svg
    width={43}
    height={43}
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.66 36.124a1 1 0 00.865 1.5h35.948a1 1 0 00.866-1.5L22.365 5.078a1 1 0 00-1.731 0L2.66 36.124zm20.631-4.874a1 1 0 01-1 1h-1.583a1 1 0 01-1-1v-1.583a1 1 0 011-1h1.583a1 1 0 011 1v1.583zm0-7.167a1 1 0 01-1 1h-1.583a1 1 0 01-1-1v-5.166a1 1 0 011-1h1.583a1 1 0 011 1v5.166z"
      fill="#F1B135"
    />
  </svg>
);

export default WarningModalIcon;
