import { APP_NAME } from '../constants';
import { EMAIL_TEMPLATES } from '../data/constants';

export const MAILS = {
  // inventoryUploadSFMail: (account) => ({
  //   templateName: EMAIL_TEMPLATES.oder_confirmation,
  //   subject: `${APP_NAME} - New inventory file upload for customer SKP-${account.skpCustomerId}`,
  //   body: `Customer SKP-${account.skpCustomerId} has uploaded a new version of their inventory file`,
  //   internal: true,
  // }),
  // inventoryUploadMail: () => ({
  //   templateName: EMAIL_TEMPLATES.oder_confirmation,
  //   subject: `${APP_NAME} - Confirmation of your inventory upload`,
  //   body: `Thank you for uploading your inventory file. It is currently under review....`,
  //   internal: false,
  // }),
  // orderConfirmSFMail: ({
  //   orderId,
  //   accountId,
  //   bundleName,
  //   additionalCharge,
  // }) => ({
  //   templateName: EMAIL_TEMPLATES.oder_confirmation,
  //   subject: `${APP_NAME} Order Request - ${
  //     additionalCharge > 0 ? 'Add-on Purchase' : 'Allotment Redemption'
  //   }`,
  //   body: `Customer ${accountId} has submitted a new order request. Please log in to the ${APP_NAME} Dashboard admin panel to proceed with the next steps.`,
  //   internal: true,
  //   meta: {
  //     cta: bundleName,
  //     orderId,
  //     additionalCharge:
  //       additionalCharge > 0
  //         ? `This request caused an additional charge of ${formatCurrency(
  //             additionalCharge
  //           )} on account.`
  //         : 'No Additional Charge',
  //   },
  // }),
  // inviteUserMail: (userName, toEmail, adminName) => ({
  //   templateName: EMAIL_TEMPLATES.oder_confirmation,
  //   subject: `Invitation: ${APP_NAME} dashboard`,
  //   body: `${adminName} has invited you to join the ${APP_NAME} dashboard where you can monitor, access, and manage your documents all from one place. If you don't already have an account with us, you will be receiving an email shortly from ${APP_EMAILS.info} to create your account. If you already have an account, you can use your existing login information to access the dashboard. If you have any questions, please don't hesitate to reach out. <br/><br/><br/> ${APP_NAME} customer support`,
  //   internal: false,
  //   toEmail,
  //   userName,
  // }),
  // deactivateUserMail: (userName, toEmail) => ({
  //   templateName: EMAIL_TEMPLATES.oder_confirmation,
  //   subject: 'Account deactivated',
  //   body: `Your ${APP_NAME} account has been deactivated. If you believe this has happened by mistake, please reach out to your dashboard admin to get re-added to your team account. <br/><br/><br/> Thank you, ${APP_NAME} support team`,
  //   internal: false,
  //   toEmail,
  //   userName,
  // }),
  contactSupportMail: (values) => ({
    templateName: EMAIL_TEMPLATES.to_customer,
    subject: `${APP_NAME} - ${values.reason}`,
    body: 'Thank you for reaching out. We have received your form submission and will reply shortly.',
    internal: false,
    meta: {
      message: values.message,
      reason: values.reason,
    },
  }),
  contactSupportSFMail: (values) => ({
    templateName: EMAIL_TEMPLATES.to_rep,
    subject: `${APP_NAME} - ${values.reason}`,
    body: values.message,
    internal: true,
    meta: {
      message: values.message,
      reason: values.reason,
    },
  }),
  IODUploadMail: (account) => ({
    templateName: EMAIL_TEMPLATES.iod_upload_success,
    subject: `${APP_NAME} - ${'File Upload Complete'}`,
    selectedAccount: account,
    isAdmin: true,
  }),
  // preTmsCustomerReAuthMail: (data) => ({
  //   templateName: EMAIL_TEMPLATES.re_auth_customer,
  //   subject: `${APP_NAME} - Payment Information Updated Successfully`,
  //   body: `This email is to confirm that ${data} has successfully updated their payment information in the ${APP_NAME} dashboard.`,
  //   internal: false,
  // }),
  // preTmsCustomerReAuthSFMail: (data) => ({
  //   templateName: EMAIL_TEMPLATES.re_auth_customer,
  //   subject: `${APP_NAME} - Payment Information Updated Successfully`,
  //   body: `This email is to confirm that ${data} has successfully updated their payment information in the ${APP_NAME} dashboard.`,
  //   internal: true,
  // }),
};
