import PropTypes from 'prop-types';
import React from 'react';
import { Container, CssBaseline, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IMAGES from '../img';
import { APP_NAME } from '../constants';

const ErrorScreen = ({ message, error }) => {
  const classes = useStyles();

  return (
    <Container>
      <CssBaseline />
      <Grid
        container
        justifyContent={'center'}
        alignItems={'center'}
        direction={'column'}
        spacing={8}
        className={classes.container}
      >
        <Grid item>
          <img
            src={IMAGES.logo}
            height={60}
            className="d-inline-block align-top"
            alt={APP_NAME}
          />
        </Grid>
        <Grid item>
          <Typography variant="h4" className={classes.message}>
            {message}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" color="textSecondary">
            Error Message: {error}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    minHeight: '100vh',
    textAlign: 'center',
  },
  message: {
    fontFamily: 'Interstate, Open Sans',
  },
}));

export default ErrorScreen;

ErrorScreen.propTypes = {
  message: PropTypes.string,
  error: PropTypes.string,
};
