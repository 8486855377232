import axios from 'axios';
import { authorizedApi, getWithExpiry, useFetch } from '../utils';
import { LOCAL_STORAGE_KEYS, SBC_BACKEND_ENDPOINTS } from '../data/constants';
import { OKTA_ORG_URL, REDIRECT_URI } from '../constants';

export const HTTP_METHOD = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  delete: 'DELETE',
};

const auth = async (jwt) => {
  return await authorizedApi(jwt).get(SBC_BACKEND_ENDPOINTS.auth);
};

export const signout = () => {
  const id_token = getWithExpiry(LOCAL_STORAGE_KEYS.oktaIdToken);
  axios.defaults.headers = null;
  window.localStorage.clear();
  let logout_url = REDIRECT_URI;
  // If session is active signout from okta
  if (id_token) {
    logout_url = `${OKTA_ORG_URL}/logout?id_token_hint=${id_token}&post_logout_redirect_uri=${REDIRECT_URI}/login`;
  }
  window.location.href = logout_url;
};

const getPricesURL = (productId, optionIds = [], isAddon = true) => {
  const query = optionIds.join(',');
  const URL = `${
    SBC_BACKEND_ENDPOINTS.price
  }/${productId}?options=${query}&isAddOn=${isAddon ? 'yes' : 'no'}`;
  return URL;
};

const removePaymentMethod = (paymentMethodId) => {
  const URL = SBC_BACKEND_ENDPOINTS.removePaymentMethod.replace(
    '{paymentMethodId}',
    paymentMethodId
  );
  return useFetch(URL, HTTP_METHOD.put);
};

const updateCreditCardInfo = (paymentMethodId, body) => {
  const URL = SBC_BACKEND_ENDPOINTS.editCreditCard.replace(
    '{paymentMethodId}',
    paymentMethodId
  );
  return useFetch(URL, HTTP_METHOD.put, body);
};

export const addDigitalWallet = (body) => {
  const URL = SBC_BACKEND_ENDPOINTS.payWithDigitalWallet;
  return useFetch(URL, HTTP_METHOD.post, body);
};

const terminateAccount = (accountId, body) => {
  return useFetch(
    SBC_BACKEND_ENDPOINTS.terminateAccount,
    HTTP_METHOD.post,
    body,
    { accountId }
  );
};

const initiateRefund = (accountId, body) => {
  return useFetch(SBC_BACKEND_ENDPOINTS.refundIssue, HTTP_METHOD.post, body, {
    accountId,
  });
};

const cancelProduct = (accountId, body) => {
  return useFetch(SBC_BACKEND_ENDPOINTS.cancelProduct, HTTP_METHOD.post, body, {
    accountId,
  });
};

const validateOrder = (body) => {
  const URL = SBC_BACKEND_ENDPOINTS.validateOrder;
  return useFetch(URL, HTTP_METHOD.post, body);
};

const createOrder = (body) => {
  const URL = SBC_BACKEND_ENDPOINTS.orders;
  return useFetch(URL, HTTP_METHOD.post, body);
};

const validateOrderWithServiceAddress = (body) => {
  const URL = SBC_BACKEND_ENDPOINTS.validateOrderWithServiceAddress;
  return useFetch(URL, HTTP_METHOD.post, body);
};

const createOrderWithServiceAddress = (body) => {
  const URL = SBC_BACKEND_ENDPOINTS.createOrders;
  return useFetch(URL, HTTP_METHOD.post, body);
};

const updateAccount = (accountId, body) => {
  const URL = SBC_BACKEND_ENDPOINTS.accounts;
  return useFetch(URL, HTTP_METHOD.put, body, { accountId });
};

const validateZipCode = (zipCode) => {
  const URL = `${SBC_BACKEND_ENDPOINTS.validateZip}?zipCode=${zipCode}`;
  return useFetch(URL, HTTP_METHOD.get);
};

const getTerminateServiceDetails = (accountId, bundleDocId) => {
  const URL = `${SBC_BACKEND_ENDPOINTS.terminateService}?accountId=${accountId}&bundleDocId=${bundleDocId}`;
  return useFetch(URL, HTTP_METHOD.get);
};

const getChargeAllDetails = (accountId, bundleDocId) => {
  const URL = `${SBC_BACKEND_ENDPOINTS.accumulatedCharges}?accountId=${accountId}&bundleDocId=${bundleDocId}`;
  return useFetch(URL, HTTP_METHOD.get);
};

const getPriceOptions = (bundle, contractType, pickupFrequencyType = null) => {
  const URL = `${SBC_BACKEND_ENDPOINTS.bundlePriceLabels}?bundleId=${bundle?.bundleTypeId}&optionTypeId=${bundle?.bundleOptionTypeId}&frequency=${contractType}&pickupFrequencyType=${pickupFrequencyType}`;
  return useFetch(URL, HTTP_METHOD.get);
};

const onServiceChanges = (body) => {
  const URL = SBC_BACKEND_ENDPOINTS.serviceChanges;
  return useFetch(URL, HTTP_METHOD.post, body);
};

const terminateService = (body, accountId) => {
  const URL = SBC_BACKEND_ENDPOINTS.terminateService;
  return useFetch(URL, HTTP_METHOD.post, body, { accountId });
};

const chargeAllService = (body) => {
  const URL = SBC_BACKEND_ENDPOINTS.chargeAllService;
  return useFetch(URL, HTTP_METHOD.post, body);
};

const makeAccountOwner = (accountId, data) => {
  const URL = SBC_BACKEND_ENDPOINTS.accounts;
  return useFetch(URL, HTTP_METHOD.put, data, { accountId });
};

const fetchAdHocFeeTax = (body) => {
  const URL = SBC_BACKEND_ENDPOINTS.processTaxForAdHocFee;
  return useFetch(URL, HTTP_METHOD.post, body);
};

const adHocFeeRequest = (body) => {
  const URL = SBC_BACKEND_ENDPOINTS.adHocFeeRequest;
  return useFetch(URL, HTTP_METHOD.post, body);
};

const pauseRestartService = (body) => {
  const URL = SBC_BACKEND_ENDPOINTS.pauseAndRestartService;
  return useFetch(URL, HTTP_METHOD.post, body);
};

const updateAddress = (body, isUpdateAccount) => {
  const URL = isUpdateAccount
    ? SBC_BACKEND_ENDPOINTS.accounts.concat('/')
    : SBC_BACKEND_ENDPOINTS.updateAddress;
  const method = isUpdateAccount ? HTTP_METHOD.put : HTTP_METHOD.post;
  const header = isUpdateAccount ? { accountId: body.accountId } : null;
  return useFetch(URL, method, body, header);
};

// Fetching account product bundles and billing/service address details
const getAccServiceDetails = (accountId) => {
  const URL = SBC_BACKEND_ENDPOINTS.serviceAddressAssociation;
  return useFetch(URL, 'GET', null, { accountId: accountId });
};

//  Get flexible bin instructions
const getProductContent = () => {
  const URL = SBC_BACKEND_ENDPOINTS.getProductContent;
  return useFetch(URL, 'GET');
};

//  Get Add On Terms and condition content
const getAddOnTermsAndConditionContent = () => {
  const URL = SBC_BACKEND_ENDPOINTS.addOnTermsAndConditionContent;
  return useFetch(URL, 'GET');
};

const retryFailedRecurringPayment = (body) => {
  const URL = SBC_BACKEND_ENDPOINTS.retryRecurringPayment;
  return useFetch(URL, HTTP_METHOD.post, body);
};

const scheduleFlexbinPickup = (body, isFromAdmin) => {
  const URL = isFromAdmin
    ? SBC_BACKEND_ENDPOINTS.scheduleAdminFlexbinPickup
    : SBC_BACKEND_ENDPOINTS.scheduleFlexbinPickup;
  const header = isFromAdmin ? { accountId: body.accountId } : null;
  return useFetch(URL, HTTP_METHOD.post, body, header);
};

const updateSkpOrderNumberRequest = (body) => {
  const URL = SBC_BACKEND_ENDPOINTS.updateSkpOrderNumberRequest;
  return useFetch(URL, HTTP_METHOD.post, body);
};

const getProductPriceDetails = (
  productType,
  buldleTypeId,
  quantity,
  includeTaxFlag,
  zipCode,
  breakupFlag,
  promoCode,
  contractType,
  pickupFrequency
) => {
  const URL = `${SBC_BACKEND_ENDPOINTS.getProductPrice}/${productType}?options=${buldleTypeId}:${quantity}&includeTax=${includeTaxFlag}&zipCode=${zipCode}&breakup=${breakupFlag}&promoCode=${promoCode}&frequency=${contractType}&pickupFrequencyType=${pickupFrequency}`;
  return useFetch(URL, HTTP_METHOD.get);
};

const purchaseReceiptRequest = (body) => {
  const URL = SBC_BACKEND_ENDPOINTS.purchaseReciptRequest;
  return useFetch(URL, HTTP_METHOD.post, body);
};

const fetchRecurringPayments = (body) => {
  const URL = SBC_BACKEND_ENDPOINTS.fetchRecurringPayments;
  return useFetch(URL, HTTP_METHOD.post, body);
};

const changeOverrideRentalFee = (accountId, body) => {
  const URL = SBC_BACKEND_ENDPOINTS.overrideRentalFee;
  return useFetch(URL, HTTP_METHOD.post, body, { accountId });
};

const addIndexFormEntryRequest = (body) => {
  const URL = SBC_BACKEND_ENDPOINTS.addIndexFormEntryRequestURL;
  return useFetch(URL, HTTP_METHOD.post, body);
};

//  Get Account warning notice information
const getAccountWarningNoticeInformation = () => {
  const URL = SBC_BACKEND_ENDPOINTS.fetchAccountWarningNoticeInformation;
  return useFetch(URL, 'GET');
};

export default {
  auth,
  getPricesURL,
  removePaymentMethod,
  updateCreditCardInfo,
  addDigitalWallet,
  terminateAccount,
  initiateRefund,
  cancelProduct,
  validateOrder,
  createOrder,
  updateAccount,
  validateZipCode,
  makeAccountOwner,
  getTerminateServiceDetails,
  getChargeAllDetails,
  chargeAllService,
  terminateService,
  getPriceOptions,
  onServiceChanges,
  validateOrderWithServiceAddress,
  createOrderWithServiceAddress,
  fetchAdHocFeeTax,
  adHocFeeRequest,
  pauseRestartService,
  getAccServiceDetails,
  getProductContent,
  retryFailedRecurringPayment,
  scheduleFlexbinPickup,
  updateAddress,
  getAddOnTermsAndConditionContent,
  updateSkpOrderNumberRequest,
  getProductPriceDetails,
  purchaseReceiptRequest,
  fetchRecurringPayments,
  changeOverrideRentalFee,
  addIndexFormEntryRequest,
  getAccountWarningNoticeInformation,
};
