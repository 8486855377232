import { useEffect, useRef } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { Workbook } from 'exceljs';
import firebase from 'firebase/app';
import 'firebase/firestore';
import CryptoJS from 'crypto-js';
import SbcBackendApi, {
  addDigitalWallet,
  HTTP_METHOD,
} from '../api/SbcBackendApi';
import {
  API_TIMEOUT,
  BEARER_TOKEN,
  GATEWAY_HOST,
  INV_TYPE,
  OKTA_AUTH_HEADER,
  PAYMENT_METHODS,
  SESSION_TIMEOUT,
} from '../constants';
import {
  BUNDLE_CHARGE_TYPE,
  INVENTORY_FILE_PATH,
  INVENTORY_FILE_SHEET_NAMES,
  LOCAL_STORAGE_KEYS,
  SBC_BACKEND_ENDPOINTS,
  SORT_ORDER,
} from '../data/constants';
import { firebaseAuth, storageRef } from './firebase';
import { APP_TIME_ZONE } from '../constants';

const MESSAGE_TYPES = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  EXCEPTION: 'EXCEPTION',
};

const DEFAULT_MESSAGE_DATA = {
  TITLE: "Something doesn't look quite right.",
  SUB_TITLE:
    "The request couldn't go through. Please contact customer service or try again later",
};

export const userLogin = async (jwt) => {
  return SbcBackendApi.auth(jwt)
    .then(async (res) => {
      const userRes = res.data.response;
      setWithExpiry(LOCAL_STORAGE_KEYS.user, userRes);
      // 3. Make sure user is signed in to Firebase
      await firebaseAuth.signInWithCustomToken(userRes.firebaseToken);
      return userRes;
    })
    .catch((err) => {
      console.error('sbc-backend auth failed', err);
      return err;
    });
};

/**
 * Parses downloaded Excel inventory file into JSON format expected by table.
 */
const parseInventoryFile = async (file, bundle) => {
  const workbook = new Workbook();
  await workbook.xlsx.load(await file.arrayBuffer());

  let formattedData = [];

  workbook.eachSheet((worksheet) => {
    // Handle box-level inventory sheet
    if (worksheet?.name.toLowerCase().includes('box')) {
      for (let i = 7; i <= worksheet.rowCount; i++) {
        const row = worksheet.getRow(i);
        const boxNumber = row.getCell(3).value?.result || row.getCell(3).value;
        if (boxNumber) {
          const datum = {
            type: INV_TYPE.box,
            status: row.getCell(10).value
              ? row.getCell(10).value
              : 'Document not received',
            skpBarcodeNumber: row.getCell(2).value,
            boxSerialNumber: boxNumber,
            creationDate:
              row.getCell(4).value && typeof row.getCell(4).value === 'object'
                ? dayjs(row.getCell(4).value).format('MM-DD-YYYY')
                : row.getCell(4).value,
            destructionDate:
              row.getCell(5).value && typeof row.getCell(5).value === 'object'
                ? dayjs(row.getCell(5).value).format('MM-DD-YYYY')
                : row.getCell(5).value,
            majorDescriptor: row.getCell(6).value,
            minorDescriptor: {
              label: 'Minor descriptor',
              value: row.getCell(7).value,
            },
            fileDescriptor1: {
              label: '',
              value: '',
            },
            fileDescriptor2: {
              label: '',
              value: '',
            },
            fileDescriptor3: {
              label: '',
              value: '',
            },
          };

          formattedData.push(datum);
        }
      }

      // Handle file-level inventory sheet
    } else if (
      worksheet?.name.toLowerCase().includes('file') &&
      bundle.name !== 'Basic'
    ) {
      if (worksheet.getCell('B6').value !== 'XXXXXXXXX') {
        const fileDescriptor1Label = worksheet.getCell('E7').value;
        const fileDescriptor2Label = worksheet.getCell('F7').value;
        const fileDescriptor3Label = worksheet.getCell('G7').value;

        for (let i = 8; i <= worksheet.rowCount; i++) {
          const row = worksheet.getRow(i);
          const boxNumber =
            row.getCell(1).value?.result || row.getCell(1).value;
          if (boxNumber) {
            const datum = {
              type: INV_TYPE.file,
              status: row.getCell(10).value,
              skpBarcodeNumber: '',
              boxSerialNumber: '',
              parentBox: boxNumber,
              creationDate:
                row.getCell(2).value && typeof row.getCell(2).value === 'object'
                  ? dayjs(row.getCell(2).value).format('MM-DD-YYYY')
                  : row.getCell(2).value,
              destructionDate:
                row.getCell(3).value && typeof row.getCell(3).value === 'object'
                  ? dayjs(row.getCell(3).value).format('MM-DD-YYYY')
                  : row.getCell(3).value,
              majorDescriptor: '',
              minorDescriptor: {
                label: '',
                value: '',
              },
              fileDescriptor1: {
                label: fileDescriptor1Label,
                value: row.getCell(5).value,
              },
              fileDescriptor2: {
                label: fileDescriptor2Label,
                value: row.getCell(6).value,
              },
              fileDescriptor3: {
                label: fileDescriptor3Label,
                value: row.getCell(7).value,
              },
            };

            formattedData.push(datum);
          }
        }
      }
    }
  });

  return formattedData;
};

export const fetchInventoryFormattedData = async (bundle, bucket) => {
  const invStorageRef = await storageRef(bucket, INVENTORY_FILE_PATH);
  const inventoryDownloadURL = await invStorageRef.getDownloadURL();
  try {
    const { data } = await axios({
      method: 'get',
      url: inventoryDownloadURL,
      responseType: 'blob',
      transformRequest: (data, headers) => {
        delete headers.common[`${OKTA_AUTH_HEADER}`];
        delete headers.common['authorization'];
        delete headers.accountId;
        return data;
      },
    });
    // 2. convert to expected JSON format
    return parseInventoryFile(data, bundle);
  } catch (error) {
    return error;
  }
};

/**
 * Validate the specified Excel file before persisting.
 *
 * TODO - validation requirements are likely to change as template file is still being updated.
 * For now, a single validation is provided as an example for future changes.
 */
export const isInventoryFileValid = (workbook) => {
  let numErrors = 0;

  // 1. Validate box sheet is present
  const boxSheet = workbook.getWorksheet(INVENTORY_FILE_SHEET_NAMES.box);
  if (!boxSheet) {
    console.error('Missing box-level indexing sheet');
    numErrors++;
  }

  // 2. Other validations
  // ... add additional checks here

  return numErrors === 0;
};

export const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const formatCurrency = (amount = 0) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);
};

export const createColumnData = (id, label, enableSort = true) => ({
  id,
  label,
  enableSort,
});

export const descendingComparator = (a, b, orderBy) => {
  if (b[String(orderBy)] < a[String(orderBy)]) {
    return -1;
  }
  if (b[String(orderBy)] > a[String(orderBy)]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) =>
  order === SORT_ORDER.desc
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const authorizedApi = (jwt, method = 'get') => {
  const bearerToken = `${BEARER_TOKEN} ${jwt}`;
  return axios.create({
    baseURL: GATEWAY_HOST,
    method,
    timeout: API_TIMEOUT,
    headers: {
      authorization: bearerToken,
      [OKTA_AUTH_HEADER]: bearerToken,
    },
  });
};

export const useFetch = async (url, method = 'get', data, headers) => {
  const config = {
    method,
    url: GATEWAY_HOST + url,
    ...(data && { data }),
    ...(headers && { headers }),
  };

  try {
    const res = await axios(config);
    return res.data;
  } catch (err) {
    let error = err;
    if (error.response) {
      error = error.response;
      if (error.data?.error?.message && error.data?.error?.code !== 500) {
        error = error.data.error;
      }
    } else if (error.request) {
      error = error.request;
    }
    return error;
  }
};

export const setAPIAuthHeader = (data, token) => {
  const { account, accounts } = data.user;
  const bearerToken = `${BEARER_TOKEN} ${token}`;
  axios.defaults.headers.common['authorization'] = bearerToken;
  axios.defaults.headers.common[`${OKTA_AUTH_HEADER}`] = bearerToken;
  if (accounts && account._id) {
    axios.defaults.headers.accountId = account._id;
  }
};

// Encrypt and save the data to local storage
export const setWithExpiry = (key, value) => {
  if (typeof value === 'object') {
    value = JSON.stringify(value);
  }
  const APP_SECRET_KEY = process.env.REACT_APP_ENCRYPTION_KEY;
  const timeout = Date.now() + SESSION_TIMEOUT;
  const encryptedValue = CryptoJS.AES.encrypt(value, APP_SECRET_KEY).toString();
  const item = {
    value: encryptedValue,
    expiry: timeout,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

// Get the decrypted data from local storage
export const getWithExpiry = (key, isObj) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  if (Date.now() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  const APP_SECRET_KEY = process.env.REACT_APP_ENCRYPTION_KEY;
  const bytes = CryptoJS.AES.decrypt(item.value, APP_SECRET_KEY);
  let decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
  if (isObj) {
    decryptedValue = JSON.parse(decryptedValue);
  }
  return decryptedValue;
};

export const remainingCreditsForBundleOption = (bundle, optionName) => {
  // #84 no plan level inclusion configuration required
  // if (!PLANS[bundle.name].includes(optionName)) return 0;

  const bundleOption = bundle.options.find(
    (option) => option.name === optionName
  );
  if (!bundleOption.allowed) return 0;
  else {
    return (
      bundleOption.allowed.credits +
      bundleOption.addOn.credits -
      (bundleOption.allowed.used + bundleOption.addOn.used)
    );
  }
};

export const orderCreator = async ({
  collection,
  orderDescription,
  additionalCharge = 0,
  selectedBundleOption,
  pickupNotes = '',
}) => {
  return await collection.add({
    type: selectedBundleOption,
    orderDescription: orderDescription,
    additionalCharge,
    status: 'pending',
    scheduledDate: firebase.firestore.Timestamp.fromDate(new Date()),
    completedDate: '',
    pickupNotes,
  });
};

const checkPendingFile = (pendingOrders, file) => {
  const isFilePending = pendingOrders.some((order) => {
    const existingFiles = order.orderItems.map((item) => item.id);
    return existingFiles.includes(file.fileDescriptor1);
  });
  return isFilePending;
};

const checkPendingBox = (pendingOrders, box) => {
  const isBoxPending = pendingOrders.some((order) => {
    const existingBox = order.orderItems.map((item) => item.id);
    return existingBox.includes(box.boxSerialNumber);
  });
  return isBoxPending;
};

// check for existing file and box status
export const getOrderPendingStatus = (
  selectedObjects,
  pendingOrders,
  inventoryData
) => {
  return new Promise((resolve) => {
    if (selectedObjects.length > 0) {
      selectedObjects.forEach((selected) => {
        if (selected.type === INV_TYPE.file) {
          // check if file is in service and disble CTA's
          const filePending = checkPendingFile(pendingOrders, selected);
          if (filePending) {
            resolve(true);
          } else {
            // check if parent box is in service and disble CTA's
            const parentBox = inventoryData.find(
              (inv) =>
                inv.type === INV_TYPE.box &&
                inv.boxSerialNumber === selected.parentBox
            );
            if (parentBox) {
              const boxPending = checkPendingBox(pendingOrders, parentBox);
              if (boxPending) {
                resolve(true);
              }
            }
          }
        } else if (selected.type === INV_TYPE.box) {
          // check if box is in service and disble CTA's
          const boxPending = checkPendingBox(pendingOrders, selected);
          if (boxPending) {
            resolve(true);
          } else {
            // check if any file in the box is in service and disble CTA's
            const filesInBox = inventoryData.filter(
              (inv) =>
                inv.parentBox === selected.boxSerialNumber &&
                inv.type === INV_TYPE.file
            );
            filesInBox.length > 0 &&
              filesInBox.map((file) => {
                const filePending = checkPendingFile(pendingOrders, file);
                if (filePending) {
                  resolve(true);
                }
              });
          }
        }
      });
    }
    resolve(false);
  });
};

// check if the account type has only one time products
export const isOTSUser = (account, accountBundleIds, recBundleTypes) => {
  const { accountType } = account;
  let isOTS = false;
  if (accountType && accountType.chargeType === BUNDLE_CHARGE_TYPE.oneTime) {
    if (accountBundleIds && accountBundleIds.length > 0) {
      const hasRecurringProducts = accountBundleIds.some((r) =>
        recBundleTypes.includes(r)
      );
      if (hasRecurringProducts) {
        isOTS = false;
      } else {
        isOTS = true;
      }
    }
  }
  return isOTS;
};

// check if account type has only recurring shred products
export const isRSUser = (account, accountBundleIds, storageBundles) => {
  const { accountType } = account;
  let isRecUser = false;
  if (
    accountType &&
    storageBundles &&
    accountType.chargeType === BUNDLE_CHARGE_TYPE.recurring
  ) {
    if (accountBundleIds && accountBundleIds.length > 0) {
      const hasStorageProducts = accountBundleIds.some((r) =>
        storageBundles.includes(r)
      );
      if (hasStorageProducts) {
        isRecUser = false;
      } else {
        isRecUser = true;
      }
    }
  }
  return isRecUser;
};

// check if account type has any flexible bin products
export const isFlBUser = (accountBundleIds) => {
  let isFlexUser = false;
  if (accountBundleIds && accountBundleIds.length > 0) {
    const hasFlexBinProducts = accountBundleIds.includes('flexibleBin');
    if (hasFlexBinProducts) {
      isFlexUser = true;
    } else {
      isFlexUser = false;
      // testing data
    }
  }

  return isFlexUser;
};

const sortByBoxName = (a, b) => {
  var numReg = /[^0-9]/g;
  var boxA =
    typeof a.boxSerialNumber === 'string'
      ? parseInt(a.boxSerialNumber?.replace(numReg, ''), 10)
      : a.boxSerialNumber;
  var boxB =
    typeof b.boxSerialNumber === 'string'
      ? parseInt(b.boxSerialNumber?.replace(numReg, ''), 10)
      : b.boxSerialNumber;
  return boxA === boxB ? 0 : boxA > boxB ? 1 : -1;
};

export const getFormattedInventoryData = (invSnapShot, statusCodes) => {
  const [boxSnapShot, fileSnapShot] = invSnapShot;
  let formattedData = [];
  boxSnapShot.forEach((doc) => {
    formattedData.push({
      ...doc.data(),
      id: doc.id,
      type: INV_TYPE.box,
      status:
        statusCodes?.inventory[doc.data().statusCode]?.label ||
        doc.data().statusCode,
    });
  });
  fileSnapShot.forEach((doc) => {
    formattedData.push({
      ...doc.data(),
      id: doc.id,
      type: INV_TYPE.file,
      parentBox: doc.data().boxSerialNumber,
    });
  });
  formattedData.sort(sortByBoxName);
  return formattedData;
};

export const getPrice = (price) => {
  let basePrice = price || 0;
  const formattedPrice = Number(basePrice).toFixed(2);
  return `$${formattedPrice}`;
};

export const getBasePrice = (data) => {
  const totalPrice = data.price || 0;
  const taxPrice = data.tax || 0;
  let basePrice = totalPrice - taxPrice;
  const formattedPrice = Number(basePrice).toFixed(2);
  return Number(formattedPrice);
};

export const getDecodedToken = (paymentToken) => {
  const decodedToken = Buffer.from(paymentToken, 'base64').toString('utf-8');
  return decodedToken;
};

export const getMailBody = ({
  orderId,
  bundleName,
  description,
  additionalCharge,
  pickupDate = null,
  isOTS,
}) => {
  let info = '';
  if (isOTS) {
    info = `We have received your request and payment for a one-time pickup and shred of up to ${description} boxes. Here is your order confirmation ID - ${orderId}.`;
  } else {
    info = `We have received your request ${
      additionalCharge > 0 ? 'and payment' : ''
    } for ${bundleName} of items ${description}. Here is your order confirmation ID - ${orderId}.<br/>
    ${pickupDate ? `Your Box pickup date is - ${pickupDate}. <br />` : ''}`;
  }
  return info;
};

export const getFlexTokenURL = async (token, getFlexUrlEndPoint) => {
  const bearerToken = `${BEARER_TOKEN} ${token}`;
  const config = {
    method: 'get',
    url: getFlexUrlEndPoint,
    headers: {
      authorization: bearerToken,
    },
  };
  try {
    const res = await axios(config);
    return res.data;
  } catch (err) {
    console.error('Failed to fetch data from getFelxData API', err);
    return err;
  }
};

export const payWithCreditCard = async (
  requestBody,
  proceedPayment = false
) => {
  try {
    var paymentBody = {
      flexToken: requestBody.paymentToken,
      account: null,
      email: requestBody.email,
      paymentToken: null,
      referenceNumber: '0',
      amount: '0',
      billingAddress: {
        ...requestBody,
        postalCode: requestBody.zipCode,
      },
      capture: false,
      paymentType: PAYMENT_METHODS.card,
      expiry: requestBody.expiry,
      updateDefaultPaymentMethod: requestBody.updateDefaultPaymentMethod,
    };
    let res,
      isUnique = true; // Check whether adding payment method is a exiting in our system
    if (!proceedPayment) {
      const uniquePaymentMethodUrl =
        SBC_BACKEND_ENDPOINTS.payWithIsUniquePaymentMethodCredidCard;
      res = await useFetch(
        uniquePaymentMethodUrl,
        HTTP_METHOD.post,
        paymentBody
      );
      if (res?.success === false) return { res, isUnique }; // Show the  failed error modal
      isUnique = res.response?.data?.isUniquePaymentMethod;
    }
    if (res?.response?.data?.isUniquePaymentMethod && !proceedPayment) {
      // If the added payment method is does not exits in our system then proceed with Add a new Payent method
      const paymentResponseObj = res?.response?.data; // Add the "payWithIsUniquePaymentMethodCredidCard" API result response to prcoeed only the Save Card
      paymentBody.paymentResponse = paymentResponseObj;
      const url = SBC_BACKEND_ENDPOINTS.payWithCreditCardBySaveMode;
      res = await useFetch(url, HTTP_METHOD.post, paymentBody);
    } else if (proceedPayment) {
      // Procded with Add new payment method
      const url = SBC_BACKEND_ENDPOINTS.payWithCreditCard;
      res = await useFetch(url, HTTP_METHOD.post, paymentBody);
    }
    return { res, isUnique };
  } catch (err) {
    console.error('Failed to pay With CreditCard ', err);
    return err;
  }
};

export const payWithBankAccount = async (data, proceedPayment = false) => {
  var requestBody = data;
  try {
    var paymentBody = {
      account: {
        type: requestBody.type,
        number: requestBody.number,
        routingNumber: requestBody.routingNumber,
      },
      email: requestBody.email,
      paymentToken: null,
      referenceNumber: '0',
      amount: '0',
      billingAddress: {
        ...requestBody,
        postalCode: requestBody.zipCode,
      },
      capture: false,
      paymentType: 'CHECK',
      expiry: requestBody.expiry,
      updateDefaultPaymentMethod: requestBody.updateDefaultPaymentMethod,
    };
    let res,
      isUnique = true;
    if (!proceedPayment) {
      const uniquePaymentMethodUrl =
        SBC_BACKEND_ENDPOINTS.payWithIsUniquePaymentMethodBankAccount;
      res = await useFetch(
        uniquePaymentMethodUrl,
        HTTP_METHOD.post,
        paymentBody
      );
      isUnique = res.response.data.isUniquePaymentMethod;
    }
    if (res?.response?.data?.isUniquePaymentMethod || proceedPayment) {
      const url = SBC_BACKEND_ENDPOINTS.payWithBankAccount;
      res = await useFetch(url, HTTP_METHOD.post, paymentBody);
    }

    return { res, isUnique };
  } catch (err) {
    console.error('Failed to pay With BankAccount ', err);
    return err;
  }
};

export const updateCreditCardDetails = async (requestBody) => {
  try {
    let paymentBody = {
      paymentMethodId: requestBody.paymentMethodId,
      expiry: requestBody.expiry,
      billingAddress: {
        ...requestBody,
        postalCode: requestBody.zipCode,
      },
    };
    if (requestBody.updateDefaultPaymentMethod) {
      paymentBody.defaultPaymentMethod = requestBody.paymentMethodId;
    }
    const res = await SbcBackendApi.updateCreditCardInfo(
      requestBody.paymentMethodId,
      paymentBody
    );
    return res;
  } catch (err) {
    console.error('Failed to pay With CreditCard ', err);
    return err;
  }
};

export const payWithDigitalWallet = async (requestBody, userInfo) => {
  try {
    let paymentBody = {
      digitalToken: requestBody.digitalToken,
      referenceNumber: '0',
      amount: '0',
      capture: false,
      paymentType: requestBody.paymentType,
      billingAddress: {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        address1: requestBody.address1,
        company: requestBody.company,
        state: requestBody.state,
        city: requestBody.city,
        country: requestBody.country,
        email: requestBody.email,
        phoneNumber: requestBody.phoneNumber,
        postalCode: requestBody.zipCode,
      },
      updateDefaultPaymentMethod: requestBody.updateDefaultPaymentMethod,
    };
    const res = await addDigitalWallet(paymentBody);
    return res;
  } catch (err) {
    console.error('Failed to pay With CreditCard ', err);
    return err;
  }
};

// Get updated fields from react-hook-form data
export function getDirtyValues(dirtyFields, allValues) {
  const updatedFields = Object.keys(dirtyFields);
  const updatedFormValues = {};
  updatedFields.forEach((key) => {
    updatedFormValues[`${key}`] = allValues[`${key}`];
  });
  return updatedFormValues;
}

// Set full service address   //
export function getFullServiceAddress(address) {
  let fullAddress = '';
  if (address) {
    fullAddress =
      (address.address1 ? address.address1 + ', ' : '') +
      (address.address2 ? address.address2 + ', ' : '') +
      (address.city ? address.city + ', ' : '') +
      (address.state ? address.state + ', ' : '') +
      (address.zipCode ? address.zipCode + '.' : '');
  }
  return fullAddress;
}

let adHocFeeList = [];

export function setAdHocFeesData(data) {
  adHocFeeList = data;
}

export function getAdHocFeesData() {
  return adHocFeeList;
}

export function debounce(fn, delay) {
  let timer;
  return function () {
    let context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
}

export const promoCodeStableSort = (array, comparator, isSortEnable) => {
  if (isSortEnable) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  } else {
    return array;
  }
};

/* Handle Succuess, Warnings and Error requests  */
export const setAPISuccessAndErrorMessages = (apiResponse) => {
  const messageData = {
    success: false,
    type: '',
    title: '',
    subTitle: '',
    statusCode: null,
  };
  if (apiResponse?.success === true) {
    // If the action TRUE
    if (apiResponse?.response?.data) {
      // If have the action data
      const {
        title = '',
        subTitle = '',
        statusCode = null,
      } = apiResponse.response.data;
      if (title) {
        // If the title does not

        if (statusCode === 200) {
          messageData.success = true;
          messageData.type = MESSAGE_TYPES.SUCCESS;
          messageData.title = title;
          messageData.subTitle = subTitle;
          messageData.statusCode = statusCode;
        } else if (statusCode === 409) {
          messageData.success = true;
          messageData.type = MESSAGE_TYPES.WARNING;
          messageData.title = title;
          messageData.subTitle = subTitle;
          messageData.statusCode = statusCode;
        } else {
          messageData.success = true;
          messageData.type = MESSAGE_TYPES.SUCCESS;
          messageData.title = title;
          messageData.subTitle = subTitle;
          messageData.statusCode = statusCode;
        }
      } else {
        // deafult message
        messageData.success = true;
        messageData.type = MESSAGE_TYPES.SUCCESS;
        messageData.title = '';
        messageData.subTitle = apiResponse?.response?.message;
        messageData.statusCode = statusCode;
      }
    } else {
      // If does not have the action data
      messageData.success = false;
      messageData.type = MESSAGE_TYPES.ERROR;
      messageData.title = '';
      messageData.subTitle = apiResponse?.response?.message;
      // messageData.statusCode=statusCode;
    }
  } else {
    // If the action FALSE
    const { message = '', code = null } = apiResponse?.error;
    if (code === 400 && message) {
      messageData.success = false;
      messageData.type = MESSAGE_TYPES.EXCEPTION;
      messageData.title = message;
      messageData.subTitle = '';
      messageData.statusCode = code;
    }
  }
  if (!messageData.title) {
    messageData.title = DEFAULT_MESSAGE_DATA.TITLE;
  }
  if (!messageData.subTitle) {
    messageData.subTitle = DEFAULT_MESSAGE_DATA.SUB_TITLE;
  }
  return messageData;
};

/* Handle Expections and Bad requests  */
export const setAPIExpectionAndErrorMessages = (errorResponse) => {
  const messageData = {
    success: false,
    type: '',
    title: '',
    subTitle: '',
    statusCode: null,
  };
  const { message = '', code = null } = errorResponse;
  if (code === 400 && message) {
    messageData.success = false;
    messageData.type = MESSAGE_TYPES.EXCEPTION;
    messageData.title = message;
    messageData.subTitle = '';
    messageData.statusCode = code;
  }
  if (!messageData.title) {
    messageData.title = DEFAULT_MESSAGE_DATA.TITLE;
  }
  if (!messageData.subTitle) {
    messageData.subTitle = DEFAULT_MESSAGE_DATA.SUB_TITLE;
  }
  return messageData;
};

export function capitalizeFirstLetter(inputString) {
  // Convert the first letter to uppercase and the rest to lowercase
  return (
    inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase()
  );
}

export function extractAndJoinByInutFieldValue(data, key) {
  /*  functions will take an array of objects, extract their input field values, and join them into a comma-separated string */
  return data.map((item) => item[`${key}`]).join(',');
}

// Function to get unique items from array of objects based on two fields
export function getUniqueItems(array, field1, field2) {
  console.log();
  // Use reduce to iterate over the array and accumulate unique items
  return Object.values(
    array.reduce((uniqueMap, obj) => {
      // Concatenate the values of the two fields to create a composite key
      const key = obj[`${field1}`] + '-' + obj[`${field2}`];
      // Store the object in the map if it doesn't exist with the same key
      if (!uniqueMap[`${key}`]) {
        uniqueMap[`${key}`] = obj;
      }
      return uniqueMap;
    }, {})
  );
}

// Function to compare dates using Unix timestamp values
export function compareDatesByUnixTimestamp(field1, field2) {
  // Convert Unix timestamps to Day.js objects
  const date1 = dayjs.unix(field1);
  const date2 = dayjs.unix(field2);
  // Compare the dates
  if (date2.isAfter(date1)) {
    return true;
  } else {
    return false;
  }
}

/* Fetch System default current Time Zone */
export const getSystemDefaultTimeZone = () => {
  let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (!timeZone) {
    timeZone = APP_TIME_ZONE;
  }
  return timeZone;
};

/* Convert Date with delay */
export const formatDateWithDelay = (
  date,
  format = 'MM/DD/YY',
  delay = false
) => {
  const timeZone = APP_TIME_ZONE;
  const timeStamp = date?._seconds || date?.seconds;
  if (timeStamp) {
    if (delay) {
      return dayjs.unix(timeStamp).add(7, 'day').tz(timeZone).format(format);
    }
    return dayjs.unix(timeStamp).tz(timeZone).format(format);
  }
  return '-';
};

/* Convert Date by using timestamp date object */
export const formatDate = (date, format = 'MM/DD/YY') => {
  const timeZone = APP_TIME_ZONE;
  const timeStamp = date?._seconds || date?.seconds;
  if (timeStamp) {
    return dayjs.unix(timeStamp).tz(timeZone).format(format);
  }
  return '-';
};

/* convert by using  Date string */
export const formatDateByString = (date, format = 'MM/DD/YYYY') => {
  const timeZone = APP_TIME_ZONE;
  if (date) {
    return dayjs(date).tz(timeZone).format(format);
  }
  return '-';
};

/* Convert by using Timestamp value */
export const formatDateByTimeStamp = (
  timeStamp,
  format = 'MM/DD/YY',
  isMilliSeconds
) => {
  if (isMilliSeconds === true) {
    // If the given TimeStamp is in Milli Seconds then convert into a seconds
    timeStamp = Math.floor(timeStamp / 1000);
  }
  const timeZone = APP_TIME_ZONE;
  if (timeStamp) {
    return dayjs.unix(timeStamp).tz(timeZone).format(format);
  }
  return '-';
};

export const getDateDiff = (date, type = 'day') => {
  const timeStamp = date?._seconds || date?.seconds;
  if (timeStamp) {
    return dayjs.unix(timeStamp).diff(Date.now(), type);
  }
  return '-';
};

/* convert Date into specific format without TimeZone*/
export const dateToSpecificFormatWithoutTimeZone = (
  date,
  format = 'MM/DD/YYYY'
) => {
  const timeStamp = date?._seconds || date?.seconds;
  if (timeStamp) {
    return dayjs.unix(timeStamp).format(format);
  }
  return '-';
};

/* Convert Camelcase into a SentenceCase */
export const camelToSentenceCase = (camelCaseStr) => {
  // Replace each capital letter with a space followed by the letter in lowercase
  const sentenceCaseStr = camelCaseStr.replace(/([A-Z])/g, ' $1').toLowerCase();
  // Capitalize the first letter of the resulting string
  return sentenceCaseStr.charAt(0).toUpperCase() + sentenceCaseStr.slice(1);
};

/*
 ** Strings: The function returns "N/A" if the string is empty, null, or undefined.
 ** Numbers: The function returns "N/A" if the value is null or undefined. 0 is treated as a valid number.
 ** Booleans: The function returns "N/A" if the value is null or undefined. true and false are both valid.
 ** Booleans: The function returns "Yes" if the value is TRUE or The function returns "No" if the value is FALSE.
 */
export const getValueOrDefault = (field, dataType, fieldName) => {
  switch (dataType) {
    case 'string':
      return field ? field : 'N/A';
    case 'number':
      return typeof field === 'number'
        ? fieldName === 'price' ||
          fieldName === 'unitPrice' ||
          fieldName === 'refundTotal' ||
          fieldName === 'refundTax' ||
          fieldName === 'refundPrice' ||
          fieldName === 'finalPrice' ||
          fieldName === 'totalAmount' ||
          fieldName === 'paymentAmount' ||
          fieldName === 'priceWithoutTax' ||
          fieldName === 'priceWithTax' ||
          fieldName === 'amount' ||
          fieldName === 'priceTax' ||
          fieldName === 'basePrice' ||
          fieldName === 'regularPrice' ||
          fieldName === 'regularUnitPrice' ||
          fieldName === 'tax'
          ? '$' + field
          : field
        : 'N/A';
    case 'boolean':
      return typeof field === 'boolean' ? (field ? 'Yes' : 'No') : 'N/A';
    default:
      return 'N/A';
  }
};

/* Validate IM Express order number */
export const isValidOrderNumber = (input) => {
  const elements = input?.split('-');
  if (elements?.length === 2 && !isNaN(elements[0]) && !isNaN(elements[1])) {
    // Return TRUE, If valid EM Express order number
    return true;
  } else {
    return false;
  }
};
