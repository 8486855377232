import React from 'react';
import PropTypes from 'prop-types';
import SBCDialog from './SBCDailog';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMsg: null,
      showModal: false,
    };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log('App Error', error.message, errorInfo);
    this.setState({ showModal: true, errorMsg: error.message });
  }

  onModalClose = () => this.setState({ showModal: false });

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <SBCDialog
          open={this.state.showModal}
          onClose={this.onModalClose}
          disableBackdropClick
        >
          <DialogTitle id="api-error-dialog">Application Error</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.state.errorMsg ||
                'Something went wrong! Please try again later.'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="medium"
              color="primary"
              variant="outlined"
              disableElevation
              onClick={this.onModalClose}
            >
              Close
            </Button>
          </DialogActions>
        </SBCDialog>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element,
};

export default ErrorBoundary;
