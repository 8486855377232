import axios from 'axios';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { signout } from '../api/SbcBackendApi';
import { LOCAL_STORAGE_KEYS } from '../data/constants';
import { getWithExpiry, setAPIAuthHeader, userLogin } from '../utils';

export const AuthContext = createContext({});

/**
 * Custom hook for providing firebaseAuth info to consuming components.
 */
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ user, token, children }) => {
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(user);
  const cookies = new Cookies();

  const logout = async () => {
    cookies.remove('re-auth');
    cookies.remove('re-auth-in-dc');
    cookies.remove('welcome_note');
    setLoading(true);
    signout();
  };

  useEffect(() => {
    setAPIAuthHeader(user, token);
  }, []);

  const reloadAccount = async (accId) => {
    setLoading(true);
    axios.defaults.headers.accountId = accId;
    const jwt = getWithExpiry(LOCAL_STORAGE_KEYS.oktaToken);
    try {
      const res = await userLogin(jwt);
      if (res.user) {
        setUserInfo(res);
        setLoading(false);
      } else {
        throw res;
      }
    } catch (error) {
      throw error.message;
    }
  };

  return (
    <AuthContext.Provider
      value={{ user: userInfo, token, loading, logout, reloadAccount }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
  user: PropTypes.object.isRequired,
  token: PropTypes.string,
};
