import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, useMediaQuery } from '@material-ui/core';
import { useAuth } from '../../hooks/useAuth';
import { useAccount } from '../../hooks/useAccount';
import Loader from '../../common/Loader';
import { useTranslation } from 'react-i18next';
import CustomSelectField from '../../common/CustomSelectField';
import CustomTextarea from '../../common/CustomTextarea';

const MESSAGE_CHAR_LIMIT = 250;

const ContactUsForm = ({ onSubmit, onCancel, defaultFormValue }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles({ isSmallScreen });
  const { user } = useAuth();
  const { account } = useAccount();
  const { t } = useTranslation();

  const [reason, setReason] = useState(
    defaultFormValue.reason ? defaultFormValue.reason : ''
  );
  const [message, setMessage] = useState('');
  const menuItems = [
    { value: 'Billing & payment', label: 'Billing & payment' },
    { value: 'Current plan & upgrades', label: 'Current plan & upgrades' },
    { value: 'Shred appointments', label: 'Shred appointments' },
    { value: 'Box pick-up appointments', label: 'Box pick-up appointments' },
    {
      value: 'Indexing & inventory listing',
      label: 'Indexing & inventory listing',
    },
    {
      value: 'IoD / digital file scan request',
      label: 'IoD / digital file scan request',
    },
    { value: 'Box retrievals', label: 'Box retrievals' },
    { value: 'Admin / Permissions', label: 'Admin / Permissions' },
    { value: 'Renewal / Cancellation', label: 'Renewal / Cancellation' },
    { value: 'Other', label: 'Other' },
  ];
  const handleSubmit = (e) => {
    e.preventDefault();

    const values = {
      name: user.user.name,
      email: user.user.email,
      customerId: account.sfAccountId,
      reason,
      message,
    };

    onSubmit(values);
  };

  const onMessageChange = (e) => {
    const newMessage = e.target.value;
    if (newMessage.length <= MESSAGE_CHAR_LIMIT) {
      setMessage(newMessage);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={classes.root}>
      {!account ? (
        <Loader />
      ) : (
        <>
          <Grid container direction={'column'}>
            <Grid item className={classes.fullWidth}>
              <Grid container direction={'row'} className={classes.topPadding}>
                <Grid item xs={12} md={4}>
                  <InputLabel shrink className={classes.fontBlue}>
                    {t('name_label')}
                  </InputLabel>
                  <Typography
                    variant={'body1'}
                    color="textSecondary"
                    className={classes.boldTextInfo}
                  >
                    {user.user.name}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  className={isSmallScreen && classes.topPadding}
                >
                  <InputLabel shrink className={classes.fontBlue}>
                    {t('email_address_label')}
                  </InputLabel>
                  <Typography
                    color="textSecondary"
                    className={classes.boldTextInfo}
                  >
                    {user.user.email}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  className={isSmallScreen && classes.topPadding}
                >
                  <InputLabel shrink className={classes.fontBlue}>
                    {t('customer_id_label')}
                  </InputLabel>
                  <Typography
                    color="textSecondary"
                    className={classes.boldTextInfo}
                  >
                    {account.sfAccountId}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container className={classes.topPadding}>
                <Grid item xs={12} sm={6} md={8}>
                  <CustomSelectField
                    label={
                      <span>
                        <span className="red-asterisk">*</span>{' '}
                        {t('reason_for_contact_label')}
                      </span>
                    }
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    options={menuItems}
                    minRows={5}
                    maxRows={6}
                    placeholder={t('select_a_reason')}
                    defaultValue=""
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.topPadding}>
                <Grid item className={classes.fullWidth}>
                  <CustomTextarea
                    label={
                      <span>
                        <span className="red-asterisk">*</span>{' '}
                        {t('message_label')}
                      </span>
                    }
                    value={message}
                    onChange={onMessageChange}
                    placeholder={t('add_a_message')}
                    helperText={`${message.length} / 250`}
                    minRows={5}
                    maxRows={5}
                  />
                </Grid>
              </Grid>
              <Grid xs={12} container className={`${classes.actionWrapper}`}>
                {isSmallScreen && (
                  <Button
                    className={classes.btnCancel}
                    onClick={onCancel}
                    xs={6}
                  >
                    {t('cancel_btn')}
                  </Button>
                )}
                <Button
                  disabled={!reason || !message}
                  variant={'contained'}
                  className={classes.submitBtn}
                  color="primary"
                  type={'submit'}
                >
                  {t('submit_btn')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFilledInput-root': {
      backgroundColor: '#f5f5f5',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 7,
    },
    '& .MuiFilledInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: 'none',
    },
  },
  fullWidth: {
    width: '100%',
  },
  customerInfoBox: {
    backgroundColor: '#f5f5f5',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 7,
    padding: theme.spacing(1),
  },
  helperText: {
    textAlign: 'right',
  },
  boldTextInfo: {
    fontSize: 18,
    fontWeight: 700,
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.black,
  },
  topPadding: {
    paddingTop: theme.spacing(4),
  },
  actionWrapper: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    justifyContent: (props) =>
      props.isSmallScreen ? 'space-evenly' : 'flex-end',
    gap: (props) =>
      !props.isSmallScreen ? theme.spacing(4) : theme.spacing(3),
    flexDirection: (props) => props.isSmallScreen && 'row',
  },
  btnCancel: {
    fontSize: 18,
    border: '1px solid #1B75BC',
    width: '128px',
    height: '48px',
    color: '#1B75BC',
    '&.Mui-disabled': {
      color: 'rgba(27, 117, 188, 0.6)',
      border: '1px solid rgba(27, 117, 188, 0.6)',
    },
    padding: '10px, 40px, 10px, 40px',
  },
  submitBtn: {
    fontSize: 18,
    width: '136px',
    height: '48px',
    background: 'linear-gradient(180deg, #1B75BC 0%, #14477D 100%)',
    '&.Mui-disabled': {
      background:
        'linear-gradient(180deg, rgba(27, 117, 188, 0.6) 0%, rgba(20, 71, 125, 0.6) 100%)',
      color: '#FFFFFF',
    },
  },
  fontBlue: {
    color: '#14477D',
  },
}));

ContactUsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  defaultFormValue: PropTypes.object,
};

export default ContactUsForm;
