import {
  ORDER_DATA_LIMIT,
  PAST_ORDER_TYPES,
  PAYMENTS_DATA_LIMIT,
} from '../data/constants';
import { firestore as db } from '../utils/firebase';

export const getActiveAccount = (accountId) => {
  return db
    .collection(`/accounts/${accountId}/bundles`)
    .where('isActive', '==', true);
};
export const getAccountBundles = (accountId) => {
  return db.collection(`/accounts/${accountId}/bundles`).get();
};

export const getAccounts = async () => {
  return await db.collection('accounts').orderBy('createdAt', 'desc').get();
};

export const getAccountInfo = async (user) => {
  const accountId = user.user.account._id;
  return await db.collection('accounts').doc(accountId).get();
};

export const getUserInfo = async (user) => {
  const accountId = user.user.account._id;
  const userId = user.user._id;
  return await db
    .collection(`accounts/${accountId}/accountUsers`)
    .doc(userId)
    .get();
};

export const getShippingAddress = async (user, shippingCode) => {
  const accountId = user.user.account._id;
  return await db
    .collection(`accounts/${accountId}/addresses`)
    .doc(shippingCode)
    .get();
};

export const getOrders = async (accountId) => {
  return await db.collection(`accounts/${accountId}/orders`).get();
};

export const fetchAccountOrderDetailsByOrderId = async (orderId) => {
  return await db
    .collectionGroup('orders')
    .where('orderId', '==', orderId)
    .get();
};

export const getAllOrders = async (accountId, all = false) => {
  const condition = all ? 'in' : 'not-in';
  return await db
    .collection(`accounts/${accountId}/orders`)
    .where('orderStatus', condition, PAST_ORDER_TYPES)
    .get();
};

export const getPastServiceOrders = async (
  accountId,
  lastVisible,
  limit = ORDER_DATA_LIMIT
) => {
  const ordersRef = db
    .collection(`accounts/${accountId}/orders`)
    .where('orderStatus', 'in', PAST_ORDER_TYPES);

  // Fetch the total count of matching documents
  const countSnapshot = await ordersRef.get();
  const totalCount = countSnapshot.size;

  // Fetch the documents with pagination
  let ordersDocRef = ordersRef.limit(limit);
  if (lastVisible) {
    ordersDocRef = ordersRef.startAfter(lastVisible).limit(limit);
  }

  const orderSnapshot = await ordersDocRef.get();
  const serviceOrders = [];

  orderSnapshot.forEach((order) => {
    const orderData = order.data();
    if (!orderData?.isSoftDelete) {
      serviceOrders.push(orderData);
    }
  });

  const lastDoc = orderSnapshot.docs[orderSnapshot.docs.length - 1];

  return {
    orders: serviceOrders.sort((a, b) => b.createdAt - a.createdAt),
    lastDoc: orderSnapshot.size >= limit ? lastDoc : null,
    totalCount, // Include the total count in the response
  };
};

export const getPendingOrders = async (accountId) => {
  const orderSnapshot = await db
    .collection(`accounts/${accountId}/orders`)
    .where('orderStatus', 'not-in', PAST_ORDER_TYPES)
    .get();
  const serviceOrders = [];
  orderSnapshot.forEach((order) => serviceOrders.push(order.data()));
  return serviceOrders;
};

export const getUpcomingServiceOrders = async (
  accountId,
  lastVisible,
  limit = ORDER_DATA_LIMIT
) => {
  const ordersCollection = db
    .collection(`accounts/${accountId}/orders`)
    .where('orderStatus', 'not-in', PAST_ORDER_TYPES)
    .orderBy('orderStatus', 'desc');

  // Step 1: Manually count the documents that match the criteria
  const countSnapshot = await ordersCollection.get();
  const totalCount = countSnapshot.size; // Total number of matching documents

  // Step 2: Fetch the limited number of orders
  let ordersRef;
  if (lastVisible) {
    ordersRef = ordersCollection.startAfter(lastVisible).limit(limit).get();
  } else {
    ordersRef = ordersCollection.limit(limit).get();
  }

  const serviceOrders = [];
  const orderSnapshot = await ordersRef;
  orderSnapshot.forEach((order) => {
    const orderData = order.data();
    if (!orderData?.isSoftDelete) {
      serviceOrders.push(orderData);
    }
  });

  const lastDoc = orderSnapshot.docs[orderSnapshot.docs.length - 1];

  return {
    orders: serviceOrders.sort((a, b) => b.createdAt - a.createdAt),
    lastDoc: orderSnapshot.size >= limit ? lastDoc : null,
    totalCount: totalCount, // Step 3: Include the total count in the return value
  };
};

export const getActiveBundles = async (user) => {
  const accountId = user.user.account._id;
  const activeBundleSnap = await db
    .collection(`accounts/${accountId}/bundles/`)
    .get();
  const activeBundles = [];
  activeBundleSnap.forEach((record) => {
    if (!record.data()?.isSoftDelete) {
      if (!record.data()?.isCancelled) {
        activeBundles.push(record.data());
      }
    }
  });
  return activeBundles;
};

export const getBundleOptions = async (accountId, bundleId) => {
  return await db
    .collection(`accounts/${accountId}/bundles/${bundleId}/bundleOptions`)
    .get();
};

export const getBundleOptionsHistory = async (
  accountID,
  bundleId,
  bundleOptionID
) => {
  return await db
    .collection(
      `accounts/${accountID}/bundles/${bundleId}/bundleOptions/${bundleOptionID}/bundleOptionsHistory/`
    )
    .get();
};

export const getSettings = async (docId) => {
  return await db.collection('settings').doc(docId).get();
};
export const getEmailTemplate = async (docId) => {
  return await db.collection('settings/common/emailTemplates').doc(docId).get();
};

export const getFaqs = async () => {
  return await db.collection('wooData').doc('faqs').get();
};

export const getAdminTasks = (accountId) => {
  return db.collection('admins/tasks/tasks').doc(accountId);
};

export const updateOrderInfo = (accountId, docId, data) => {
  return db.collection(`accounts/${accountId}/orders`).doc(docId).update(data);
};

export const updateFileInfo = async (data, accountId) => {
  return await db
    .collection('events/uploadInvFile/staging')
    .doc(accountId)
    .set(data);
};

export const getEventFileInfo = async (accountId) => {
  return await db
    .collection('events/uploadInvFile/staging')
    .doc(accountId)
    .get();
};

export const getEventFileMetaInfo = async (accountId) => {
  const metaDataSnap = await db
    .collection(`events/newInvMetadata/staging`)
    .where('accountId', '==', accountId)
    .limit(1)
    .get();

  const fileMetaData = [];
  metaDataSnap.forEach((record) => fileMetaData.push(record.data()));
  return fileMetaData;
};

export const getInventoryData = async (accountId, type, id) => {
  let docRef = db
    .collection(`accounts/${accountId}/inventory/data/${type}`)
    .get();
  if (id) {
    docRef = db
      .collection(`accounts/${accountId}/inventory/data/${type}`)
      .doc(id)
      .get();
  }
  return await docRef;
};

export const getInventoryError = async (accountId, type) => {
  return await db
    .collection(`accounts/${accountId}/inventory/errors/${type}`)
    .limit(10)
    .get();
};
export const getInventoryErrorCount = async (accountId) => {
  return await db
    .collection(`accounts/${accountId}/inventory`)
    .doc('errors')
    .get();
};

export const getProductBundles = async (bundleTypeId) => {
  return await db.collection('products/data/bundles').doc(bundleTypeId).get();
};

export const getProductBundleInfoByAccountIdAndBundleTypeId = async (
  accountId,
  docId
) => {
  return await db.collection(`accounts/${accountId}/bundles`).doc(docId).get();
};

export const getAllProductBundles = async () => {
  return await db.collection('products/data/bundles').get();
};

export const getProductPrices = async (bundleTypeId) => {
  return await db.collection('products/data/pricing').doc(bundleTypeId).get();
};

export const getPaymentType = async (user, paymentId) => {
  const accountId = user.user.account._id;
  return await db
    .collection(`accounts/${accountId}/paymentMethods`)
    .doc(paymentId)
    .get();
};

export const getPaymentHistory = (
  accountId,
  lastVisible,
  limit = PAYMENTS_DATA_LIMIT
) => {
  if (lastVisible) {
    return db
      .collection(`accounts/${accountId}/paymentHistory`)
      .orderBy('createdAt', 'desc')
      .startAfter(lastVisible)
      .limit(limit)
      .get();
  } else {
    return db
      .collection(`accounts/${accountId}/paymentHistory`)
      .orderBy('createdAt', 'desc')
      .limit(limit)
      .get();
  }
};

export const getPaymentHistoryDoc = (
  accountId,
  limit = PAYMENTS_DATA_LIMIT
) => {
  return db
    .collection(`accounts/${accountId}/paymentHistory`)
    .orderBy('createdAt', 'desc')
    .limit(limit);
};

export const getPaymentList = async (user) => {
  const accountId = user.user.account._id;
  return await db.collection(`accounts/${accountId}/paymentMethods`).get();
};

export const getAllUserList = async (accountId) => {
  return await db.collection(`accounts/${accountId}/accountUsers`).get();
};

export const getBillingAddress = async (user, billingCode) => {
  const accountId = user?.user?.account?._id;
  return await db
    .collection(`accounts/${accountId}/addresses`)
    .doc(billingCode)
    .get();
};

/* Check given Order Number is exit in specific account orders collection
 ** Exits then return TRUE
 ** Not exits then return FALSE
 */
export const doesOrderIdExist = async (orderId, accountId) => {
  try {
    // Reference to the 'orders' collection under the specific account
    const ordersRef = db
      .collection('accounts')
      .doc(accountId)
      .collection('orders');
    // Query the collection to find a document with the given orderId
    const querySnapshot = await ordersRef.where('orderId', '==', orderId).get();
    // If any documents match, return true
    if (!querySnapshot.empty) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error checking order number:', error);
    return false;
  }
};

export const getPaymentHistoryRecords = async (accountId) => {
  return await db.collection(`accounts/${accountId}/paymentHistory`).get();
};
