import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en_US from './locales/en_US.json';

i18n.use(initReactI18next).init({
  debug: false,
  lng: 'en',
  fallbackLng: 'en',

  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },

  resources: {
    en: { translations: en_US },
  },
  ns: ['translations'],
  defaultNS: 'translations',
});

export default i18n;
