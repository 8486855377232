import React, { lazy, Suspense, useEffect } from 'react';
import Proptypes from 'prop-types';
import { CookiesProvider } from 'react-cookie';
import { useIdleTimer } from 'react-idle-timer';

import APIErrorNotification from './common/APIErrorNotification';
import ModalProvider from './common/providers/ModalProvider';
import ContactUsModal from './components/Modals/ContactUsModal';
import { AccountProvider } from './hooks/useAccount';
import { EmailProvider } from './hooks/useEmail';
import useAPIError from './hooks/useAPIError';
import dayjs from 'dayjs';
import ErrorBoundary from './common/ErrorBoundary';
import Loader from './common/Loader';
import './App.css';
import { useAuth } from './hooks/useAuth';
import initChat from './utils/chat';
import { APP_IDLE_TIMEOUT } from './constants';

// Lazy load apps
const AdminApp = lazy(() => import('./AdminApp'));
const CustomerApp = lazy(() => import('./CustomerApp'));
//set default time zone cnfigurations
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);

const App = ({ user }) => {
  const RenderApp = user.user.isAdmin ? AdminApp : CustomerApp;
  const { addError } = useAPIError();
  const { logout } = useAuth();

  useEffect(() => {
    // Initialize chat for customers
    if (!user.user.isAdmin) {
      initChat();
    }
  }, []);

  // handle session timeout
  const handleOnIdle = () => {
    const errorInfo = {
      message:
        'Your session has timed out due to inactivity. Please login to access your dashboard.',
      status: 403,
    };
    addError(errorInfo);
    logout();
  };

  // initialize the idle timer to handle inactivity session timeout
  useIdleTimer({
    timeout: 1000 * 60 * APP_IDLE_TIMEOUT,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={<Loader fullHeight />}>
        <AccountProvider>
          <EmailProvider>
            <ModalProvider>
              <CookiesProvider>
                <RenderApp />
                <ContactUsModal />
              </CookiesProvider>
              <APIErrorNotification />
            </ModalProvider>
          </EmailProvider>
        </AccountProvider>
      </Suspense>
    </ErrorBoundary>
  );
};

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div>
      <h1>An error occurred: {error.message}</h1>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

ErrorFallback.propTypes = {
  error: Proptypes.object,
  resetErrorBoundary: Proptypes.func,
};

App.propTypes = {
  user: Proptypes.object.isRequired,
};

export default App;
