import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getWithExpiry } from '../../utils';
import { LOCAL_STORAGE_KEYS } from '../../data/constants';

export const APIErrorContext = React.createContext({
  error: null,
  addError: () => {},
  removeError: () => {},
});

export default function APIErrorProvider({ children }) {
  const [error, setError] = useState(null);

  const removeError = () => setError(null);

  const addError = (message) => {
    let errorMsg = typeof message === 'object' ? message : { message };
    if (!errorMsg?.status === 403) {
      // check if session is active
      const isActive = getWithExpiry(LOCAL_STORAGE_KEYS.oktaToken);
      if (!isActive) {
        errorMsg = {
          message:
            'Your session has expired. Please login to access your dashboard.',
          status: 403,
        };
      }
    }
    setError(errorMsg);
  };

  const contextValue = {
    error,
    addError: useCallback((message) => addError(message), []),
    removeError: useCallback(() => removeError(), []),
  };

  return (
    <APIErrorContext.Provider value={contextValue}>
      {children}
    </APIErrorContext.Provider>
  );
}

APIErrorProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
